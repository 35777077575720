import {
    useEffect
} from 'react';

//atoms
import { useAtom } from 'jotai'
import { categoryAtom } from 'atoms/categoryAtom';

//hooks
import useService from "./useSupportService";
//import useIsMountedRef from "./useIsMountedRef";
import useHandleGenericApiResult from './useHandleGenericApiResult';

//---------------------------------------------------

const useCategoryLookup = ()=>{
    const [categoryState, setCategoryState] = useAtom(categoryAtom);
    const {isFetching, isLoaded, categories} = categoryState;
    const {getCategories} = useService();
    const { handleObjectResult } = useHandleGenericApiResult();

    const handleGetCategories = async()=>{
        if(categories.length > 0 || isLoaded || isFetching) return Promise.resolve();
        
        setCategoryState(prev=>({
            ...prev,
            isFetching: true
        }));
        
        
        return handleObjectResult(getCategories())
        .then(resp=>{
            setCategoryState(prev=>({
                ...prev,
                isLoaded: true,
                categories: resp
            }));
           
        })
        .finally(()=>{
            setCategoryState(prev=>({
                ...prev,
                isFetching: false
            }));
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    // const fetchCategories = async()=>{
    //     if(categories.length > 0) return Promise.resolve();
    //     console.log("call")
    //     return handleGetCategories();
    // }

    useEffect(()=>{
        // console.log("categories", categories);
        // console.log("isFetching", isFetching);
        // console.log("isLoaded", isLoaded);
        if(isLoaded) return;
        if(isFetching) return;
        if(categories.length > 0) return;
        handleGetCategories();
        
        

        //fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[categories, isFetching, isLoaded])

    return {
        categories,
        handleGetCategories,
    }


}

export default useCategoryLookup;