
import {
    // createBrowserRouter,
    // RouterProvider,
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";


//components
import Contact from 'views/Contact';
import Ticket from 'views/Ticket';
import Reply from 'views/Reply';
import NotFound from 'views/NotFound';

//styles
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "./stylesheets/support.scss";

const theme = createTheme({
  palette: {
    primary: { main: '#323E4D' }
  },
});

//------------------------------------------------------------------
const App =()=>{
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={ <Contact />} />
              <Route path="/404" element={<NotFound />} />
              <Route path="/:ticketRef/reply" element={<Reply />} />
              <Route path="/:ticketRef" element={<Ticket />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
      </ThemeProvider>
     
      // <RouterProvider router={router} />
    )
}

export default App;