import { useState } from 'react';

//utils
import imageCompression from "browser-image-compression";

//hooks
import useSnackBar from './useSnackBar';
import useService from "./useSupportService";
//import useIsMountedRef from "./useIsMountedRef";
import useHandleGenericApiResult from './useHandleGenericApiResult';

//----------------------------------------------------------------------------

const useUploadAttachment = (elmID)=>{
    const [attachments, setAttachments] = useState([]);
    const {uploadAttachment: uploadAttachmentCall} = useService();
    const { handleObjectResult } = useHandleGenericApiResult();
    const {showError} = useSnackBar();
    const [isUploading, setIsUploading] = useState(false);
    const removeAttachment = (att)=>{
        setAttachments(prev=>prev.filter(cur=>cur!==att));
    }

    const uploadAttachment = async(imgFile)=>{
        if(isUploading) return;
        if (window.FormData !== undefined) {
            const data = new FormData();
            data.append("file", imgFile, imgFile.name);
            setIsUploading(true);
            return handleObjectResult(uploadAttachmentCall(data, ()=>{}))
                    .then(resp=>{
                        if(resp.length === 0){
                          showError("Failed uploading attachment");
                        }
                        else {
                          const first = [...resp].shift();
                          if(first.FileID > 0 && first.IsSuccess) {
                            setAttachments(prev=>([...prev, ...resp]));
                          }
                          else if(first.Message) {
                            showError(first.Message);
                          }
                          else {
                              showError("Failed uploading file");
                          }
                        }
                       
                        document.getElementById(elmID).value = "";
                    })
                    .finally(()=>setIsUploading(false));
        }
        else {
            document.getElementById(elmID).value = "";
            showError("your browser does not support file upload");
        }
    }

    const isFileImage = (file) => {
      const acceptedImageTypes = [ 'image/jpeg', 'image/png'];
   
      return file && acceptedImageTypes.includes(file['type'])
  }

    const onImageDrop = (e)=>{
        let files = e.target.files;
    
        if (files.length < 1) {
          showError('No file selected');
          return;
        }
        else if (files.length > 1) {
            showError('Please select one file only');
          return;
        }
        else {
          let file = files[0];
          //if (!file.type.match('image.*')) {
          if(!isFileImage(file)){
            showError('Accepted jpg|png image file only');
            return;
          }
    
          if (file.size > 12000000) {
            showError('Image size must below 12 MB');
            return;
          }
          
          try {
            imageCompression(file, {maxSizeMB: 4})
            .then((compressedFile)=>{
                uploadAttachment(new File([compressedFile], file.name));
            })
           
            
          } catch (error) {
            showError('Failed to compress the file: ' + error);
          }
        }
    }

    return {
        attachments,
        removeAttachment,
        //uploadAttachment,
        onImageDrop
    };
}

export default useUploadAttachment;