import moment from "moment";
import rhpLogo from "../images/footer/rhp-logo-2019-keyline.svg";
import appStore from "../images/footer/app-store.svg";
import googlePlay from "../images/footer/google-play.svg";
import facebookIco from "../images/footer/facebook-ico.png";
import instagramIco from "../images/footer/instagram-ico.png";
import twitterIco from "../images/footer/twitter-ico.png";
import youtubeIco from "../images/footer/youtube-ico.png";
import supportGirl from "../images/footer/support-girl.jpg";

import "../stylesheets/footer.scss";

const Footer = ()=>{
    
    return (
        <div className="footer-login-screen footer-grey">

        <div className="page-container footer-login-wrapper">
            <div className="footer-icons __left-column">
                <div className="logo"><img src={rhpLogo} alt="RHPFlatpieFooter" /></div>
                
                <div className="get-app-social">
                    <div className="store">
                        <p className="title-header">Get the RedHotPie app</p>
                        <a href="https://itunes.apple.com/app/rhpmobile/id405146852?mt=8"><img style={{width:"118px"}}  src={appStore} alt="appStore" /></a>
                        <a href="https://redhotpie.com.au/app/Android"><img  style={{width:"135px"}} src={googlePlay} alt="googlePlay" /></a>
                    </div>
                    {/* <div className="get-social">
                        <p className="title-header">Get social with us</p>
                        <div className="social">
                            <a href="https://www.facebook.com/redhotpieapp/" title="Click to visit" target="_blank" rel="noreferrer"><img src={facebookIco} alt="Icon Facebook Footer" /></a>
                            <a href="https://instagram.com/redhotpieapp" title="Click to visit" target="_blank" rel="noreferrer"><img  src={instagramIco} alt="Icon Instagram Footer" /></a>
                            <a href="https://twitter.com/redhotpieapp" title="Click to visit" target="_blank" rel="noreferrer"><img  src={twitterIco} alt="Icon Twitter Footer" /></a>
                            <a href="https://www.youtube.com/channel/UCNlnowKVYWLVzQuXQ5GBXDw" title="Click to visit" target="_blank" rel="noreferrer"><img  src={youtubeIco} alt="Icon Youtube Footer" /></a>
                        </div>
                    </div> */}
                </div>
                
            </div>
            
            <div className="footer-nav">
                    <p className="button-support"><a href="https://help.redhotpie.com/" className="ButtonSupport"><img src={supportGirl} alt="faq"/> <span>Contact Us</span></a></p>
            </div>
        </div>
        <div className="footer-all-right">
                <span>&reg; 2001 - {moment().year()} Digital Quarter Pty Ltd - All Rights Reserved</span>
                <span>The word RedHotPie and the RedHotPie logo style are trademarks of Digital Quarter Pty Ltd.</span>
            </div>
    </div>
    )
}

export default Footer;