import React from "react";

class StringUtil {
    static addLineBreaks = string =>
    string.split('\n').map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
  ));
}

export default StringUtil;