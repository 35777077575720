import { atomWithReset } from 'jotai/utils'
import {MESSAGE_TYPE} from "../config";

// ----------------------------------------------------------------------

const initVal = {
        MessageType: MESSAGE_TYPE.SUCCESS,
        Message: '',
        Display: false,
        DisplayTime: 1000
};

const snackBarMessageAtom = atomWithReset(initVal);

export default snackBarMessageAtom;