import fetch from "utils/fetch";

const useSupportService = ()=>{
    const createTicket = async(data)=>fetch.post(`support/contact`, data);
    const getTicket = async(ticketRef)=>fetch.get(`support/${ticketRef}`);
    const postReply = async(ticketRef, data)=>fetch.post(`support/${ticketRef}`, data);
    const getCategories = async()=>fetch.get(`lookup/supportTicketCategory`);
    const uploadAttachment = (data, onUploadProgress)=>fetch.post(`support/attachments`, data, {
        headers: {
         ...fetch.headers,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });

    return {
        createTicket,
        getTicket,
        postReply,
        getCategories,
        uploadAttachment
    }
}

export default useSupportService;