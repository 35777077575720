import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrownOpen } from '@fortawesome/free-regular-svg-icons';

const ticketNotFound = () => {
    return (
        <>
            <div className="content-404">
                <p className="ico"><FontAwesomeIcon icon={faFaceFrownOpen} /></p>
                <p><strong>Error!</strong></p>
                <p>Ticket not found</p>
            </div>
        </>
    );
}
               
export default ticketNotFound;