import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------
const fetch = axios.create({
    baseURL: HOST_API,
    headers: {
        Version: "support",
        Accept: "application/json",
        "Content-Type": "application/json"
    },
  });
  
  fetch.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  );
  
  // note this is for public fetch
  export default fetch;