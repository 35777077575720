import React, {useEffect} from "react";

//atoms
import { useAtom } from "jotai";
import { RESET } from 'jotai/utils'
import snackBarMessageAtom from "../atoms/snackBarMessageAtom";

// hooks
//import useIsMountedRef from "../hooks/useIsMountedRef";

//layout components
import Header from "./header";
import Footer from "./footer";

//mui
import { Snackbar, Alert } from "@mui/material";


//--------------------------------------------------------------------------

const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
    return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
});

//--------------------------------------------------------------------

const MainLayout = ({children})=>{
    //const isMountedRef = useIsMountedRef();
    const [snackBar, setSnackBar] = useAtom(snackBarMessageAtom);
    const {MessageType, Message, Display, DisplayTime} = snackBar;

    const resetSnackBar = ()=>{
        setSnackBar(RESET);
    }

    
    useEffect(()=>{
        if(Display && Message){
            setTimeout(() => {
                //if (isMountedRef.current) 
                resetSnackBar();
              }, DisplayTime);
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Display, Message, DisplayTime])

    const handleCloseSnackBar = ()=>{
        //console.log("isMountedRef",isMountedRef)
        //if (isMountedRef.current) 
        resetSnackBar();
    }

    return (
        <>
            <Header />
            {children}
            <Footer />
            {
                Display && Message &&
                <Snackbar open={Display} 
                    onClose={handleCloseSnackBar}
                    anchorOrigin={{ vertical:'top', horizontal:"center" }}
                    >
                    <CustomAlert onClose={handleCloseSnackBar} severity={MessageType} sx={{ width: '100%' }}>
                        {Message}
                    </CustomAlert>
                </Snackbar>
            }
        </>
    )
}

export default MainLayout;