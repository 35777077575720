import RHPLogo from '../images/rhp-logo.svg';
import QuestionMarkGreen from '../images/question-mark-rounded-green.svg';
import WarningRed from '../images/warning-rounded-red.svg';
import ChimeYellow from '../images/chime-rounded-yellow.svg';
import BackArrowDarkGrey from '../images/arrow-backward-bold-dark-grey.svg';
import CrossThinWhite from '../images/cross-thin-white.svg';
import QuestionMarkCircularGrey from '../images/question-mark-circular-grey.svg';
import SupportGrey from '../images/support-grey.svg';
import PlusRoundedDarkGrey from '../images/plus-rounded-dark-grey.svg';
import RHPIco from '../images/rhp-ico.svg';
import UserIco from '../images/user-ico.svg';


export const ImageIcons = {
    'RHPLogo': RHPLogo,
    'QuestionMarkGreen': QuestionMarkGreen,
    'WarningRed': WarningRed,
    'ChimeYellow': ChimeYellow,
    'BackArrowDarkGrey': BackArrowDarkGrey,
    'CrossThinWhite': CrossThinWhite,
    'QuestionMarkCircularGrey': QuestionMarkCircularGrey,
    'SupportGrey': SupportGrey,
    'PlusRoundedDarkGrey': PlusRoundedDarkGrey,
    'RHPIco': RHPIco,
    'UserIco': UserIco
}