
//hooks
import useSnackBar from "./useSnackBar";
import useIsMountedRef from "./useIsMountedRef";

//utils
import handleFetchErrorResponse from "../utils/handleFetchErrorResponse";

//----------------------------------------------------------------------

const useHandleGenericApiResult =()=>{
    const isMountedRef = useIsMountedRef();
    const { showError } = useSnackBar();

    const handleBoolResult = async(result)=>{
        return result
        .then(
            async ({data})=>{
                const {Result} = data;

                if(Result === true){
                    return Promise.resolve();
                }
                else{
                    if (isMountedRef.current) showError("Unexpected error: failed saving to db");
                    return Promise.reject();
                }
            },
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                return Promise.reject();
            }
        )
    }

    const handleHttpOkResult = async(result)=>{
        return result.then(
            async ()=>Promise.resolve(),
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                return Promise.reject();
            }
        )
    }

    const handleObjectResult = async(result, storeData)=>{
        return result
        .then(
            ({data})=>{
                if(storeData) storeData(data);
                return data;                
            },
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                throw new Error(handleFetchErrorResponse(err))
            }
        )
    }

    const handleListResult = async(result)=>{
        return result
        .then(
            ({data})=>{
                return data;                
            },
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                return {Data:[], RecordCount:0};
            }
        )
    }

    const handleListPaginationInfoResult = async(result, storeData)=>{
        return result
        .then(
            ({data})=>{
                if(storeData) storeData(data);
                return data;                
            },
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                return {Data:[], PaginationInfo:{
                    TotalItems : 0,
                    TotalPages: 1,
                    Page: 1,
                    Size: 10
                }};
            }
        )
    }

    const handleArrayResult = async(result)=>{
        return result
        .then(
            ({data})=>{
                return data;                
            },
            (err) => {
                if (isMountedRef.current) showError(handleFetchErrorResponse(err));
                return [];
            }
        )
    }

    return {
        handleObjectResult,
        handleBoolResult, 
        handleHttpOkResult,
        handleListResult,
        handleArrayResult,
        handleListPaginationInfoResult
    }
    
}

export default useHandleGenericApiResult;