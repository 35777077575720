import { useState } from 'react';
import { useNavigate } from "react-router-dom"

//forms validation
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextAreaAutosize } from './hook-form'; 

//hooks
import useUploadAttachment from '../hooks/useUploadAttachment';
import useSupportService from '../hooks/useSupportService';
import useHandleGenericApiResult from '../hooks/useHandleGenericApiResult';

//components
import PopupDialog from './popupDialog';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

//css
import "../stylesheets/reply.scss";

//------------------------------------------------------------------------

const TicketReplyForm = ({ticketRef})=>{
    const navigate = useNavigate();
    const {postReply} = useSupportService();
    const { handleObjectResult } = useHandleGenericApiResult();
    const uploaderElmID = "uploader";
    const {
        attachments,
        removeAttachment,
        onImageDrop 
    } = useUploadAttachment(uploaderElmID);
    const [showAttachment, setShowAttachment] = useState(false);
    const [attachment, setAttachment] = useState(null);

    const valSchema = Yup.object().shape({
        detail: Yup.string().required("Please leave a message").min(10, "Message is too short")
    });

    const defaultValues = { detail: '' };
    const methods = useForm({resolver: yupResolver(valSchema),defaultValues,});
    const { handleSubmit,  watch, formState: { errors } } = methods;
    const { detail } = watch();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = () => {
        if(isSubmitting) return;
        const data = {
            TicketRef : ticketRef,
            Message : detail,
            Attachments: attachments.map(att=> parseInt(att.FileID, 10))
        };
        setIsSubmitting(true);
        handleObjectResult(postReply(ticketRef, data))
            .then(()=>navigate(`/${ticketRef}`))
            .finally(()=>setIsSubmitting(false));
    }

    const onAttachmentClicked = (att)=>{
        setAttachment(att);
        setShowAttachment(true);
    }

    return (
        
        <>      
        <div className="reply">
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="input details">
                    
                    <p className={!!errors.detail?.message ? 'error' : ''}>
                        <RHFTextAreaAutosize name="detail" 
                            placeholder="Type in your reply here.."
                            minRows={5}
                            style={{ width: "100%" }}
                        />
                        {/* {!!errors.detail?.message && <span className="msg">{errors.detail?.message}</span>} */}
                    </p>
                </fieldset>
                <fieldset className="submit">
                    <p>
                        <label htmlFor="uploader"><FontAwesomeIcon icon={faPaperclip} /> Attach Screenshot</label> 
                        <input id={uploaderElmID} type="file" name="img" onChange={onImageDrop}  />
                    </p>
                    <p className='buttons'>
                        <button className="green btn back" type="button" onClick={()=>navigate(`/${ticketRef}`)}>Back</button>
                        <button className="green btn rounded" type="submit">Send</button>
                    </p>
                    
                </fieldset>
                {
                    attachments.length > 0 
                        &&
                        <ul className="att-list">
                            {
                                attachments.map((att, i)=>
                                    <li  key={att.FileID} style={{cursor:"pointer"}}>
                                        <span onClick={()=>{ onAttachmentClicked(att)}}>Attached {i + 1}</span> 
                                        <span onClick={()=>{ removeAttachment(att)}}>✕</span>
                                    </li>
                            )}
                        </ul>
                        
                        
                }
                {
                    showAttachment && attachment
                    && 
                    <PopupDialog
                    className="att-pop" 
                    classes={{paper: "dialog-paper"}} 
                    open={showAttachment}
                    close={()=>setShowAttachment(false)} 
                    content={<p><img src={attachment.AttachmentUrl} alt="Attachment" /></p>} />
                }
               </FormProvider>
        </div>
        
        </>
    )
}

export default TicketReplyForm;