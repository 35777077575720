import moment from 'moment';

//--------------------------------------------------------------------------
class DateUtil {
  /**
   * Generate time duration display text (eg: just now, 1 minute, 5 days, etc)
   * @param date - Date object
   * @returns String
   */
  static generateMessageTime = (date) => {
    const dur = moment.duration(moment().diff(moment(date)));
    if (dur.asSeconds() < 60) {
      return "just now";
    }

    const asMnt = Math.floor(dur.asMinutes());
    if (asMnt < 60) {
      return (asMnt + " minute" + (asMnt > 1 ? "s" : ""));
    }

    const asHr = Math.floor(dur.asHours());
    if (asHr < 24) {
      return (asHr + " hour" + (asHr > 1 ? "s" : ""));
    }

    const asDays = Math.floor(dur.asDays());
    if (asDays < 32) {
      return (asDays + " day" + (asDays > 1 ? "s" : ""));
    }

    var asMonth = Math.floor(dur.asMonths());
    if (asMonth < 7) {
      return (asMonth + " month" + (asMonth > 1 ? "s" : ""));
    }

    return "> 7 months"
  }
}

export default DateUtil;