
import rhpLogo from "../images/header/rhp-logo-2019.svg";
import rhpEdi from "../images/header/logo-edi.png";

import "../stylesheets/header.scss";

const Header = ()=>{
    
    return (
        <div className="SHeaderWrapper SHomePage SHeaderLoggedOut">
            <header className="header">
                <div className="header-container">
                        <div className="main-logo">
                            <a title="RedHotPie" href="https://redhotpie.com.au">
                                <img src={rhpLogo} />
                            </a>
                        </div>
                        <span className="header-slogan"><img src={rhpEdi} /></span>
                </div>
            </header>
        </div>
    )
}

export default Header;