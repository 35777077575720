// atoms
import { useUpdateAtom } from 'jotai/utils'
import snackBarMessageAtom from "../atoms/snackBarMessageAtom";

// config
import {MESSAGE_TYPE} from "../config";

// ----------------------------------------------------------------------

const useSnackBar = ()=>{
    const setSnackBar = useUpdateAtom(snackBarMessageAtom);

    const displayMessage = (message, messageType, displayTime=1000)=>{
        setSnackBar((prev)=>({
            ...prev,
            MessageType: messageType,
            Message: message,
            Display: true,
            DisplayTime: displayTime
        }));
    }

    const showError = (message, displayTime = 3000)=>{
        displayMessage(message, MESSAGE_TYPE.ERROR, displayTime);
    }

    const showSuccess = (message, displayTime = 1000)=>{
        displayMessage(message, MESSAGE_TYPE.SUCCESS, displayTime);
    }

    const showWarning = (message, displayTime = 1000)=>{
        displayMessage(message, MESSAGE_TYPE.WARNING, displayTime);
    }

    const showInfo = (message, displayTime = 1000)=>{
        displayMessage(message, MESSAGE_TYPE.INFO, displayTime);
    }

    return {
        showError, showSuccess, showWarning, showInfo
    }
}

export default useSnackBar;