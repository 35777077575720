import { useState } from 'react';
import { useNavigate } from "react-router-dom"

//forms validation
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField, RHFTextAreaAutosize } from './hook-form'; 

//hooks
import useUploadAttachment from '../hooks/useUploadAttachment';
import useCategoryLookup from "../hooks/useCategoryLookup";
import useSupportService from '../hooks/useSupportService';
import useHandleGenericApiResult from '../hooks/useHandleGenericApiResult';

//components
import CategoryDialog from './categoryDialog';
import PopupDialog from './popupDialog';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faUser, faEnvelope, faTag, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// //css
// import "../stylesheets/contact.scss";

//------------------------------------------------------------------------

const ContactForm = ()=>{
    const navigate = useNavigate();
    const {createTicket} = useSupportService();
    const { handleObjectResult } = useHandleGenericApiResult();
    const {categories} = useCategoryLookup();
    const uploaderElmID = "uploader";
    const {
        attachments,
        removeAttachment,
        //uploadAttachment,
        onImageDrop 
    }= useUploadAttachment(uploaderElmID);
    const [showCategory, setShowCategory] = useState(false);
    const [showAttachment, setShowAttachment] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const valSchema = Yup.object().shape({
        username: Yup.string().max(16, 'Username must be less than 16 characters!'),//.required("Please insert your username"),
        email: Yup.string().email().required("Please input a valid email address"),
        category: Yup.number().typeError('Please select a category').required("Please select a category"),
        subject: Yup.string().required("Please type in a subject"),
        detail: Yup.string().required("Please leave a message").min(10, "Message is too short")
    });

    const defaultValues = { 
        username: '', 
        email: '', 
        category: '', 
        subject: '',
        detail: ''
    };

    const methods = useForm({
        resolver: yupResolver(valSchema),
        defaultValues,
    });

    const { handleSubmit,  setValue, watch, trigger, formState: { errors } } = methods;
    const { username, email, category, subject, detail } = watch();

    const onSubmit = () => {
        if(isSubmitting) return;
        const data = {
            EmailAddress : email,
            SuppliedUsername : username,
            Subject : subject,
            Enquiry : detail,
            CategoryID : category,
            SiteCountryID : 1,
            UserAgent : '',
            IPAddress : '',
            Attachments: attachments.map(att=> parseInt(att.FileID, 10))
        };
        setIsSubmitting(true);
        handleObjectResult(createTicket(data))
            .then(resp=>navigate(`/${resp.TicketRef}`))
            .finally(()=>setIsSubmitting(false));
    }

    const onCategoryClicked =(e)=>{
        e.preventDefault();
        setShowCategory(true);
    }

    const onCategoryChanged = (e) =>{
        setValue("category", parseInt(e.target.value));
        trigger(["category"]);
        setShowCategory(false);
    }

    const onAttachmentClicked = (att)=>{
        setAttachment(att);
        setShowAttachment(true);
    }

    return (
        
        <>      
        <div className="contact">
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="input details">
                    <div className={!!errors.username?.message ? 'error' : ''}>
                        <label htmlFor="username"><FontAwesomeIcon icon={faUser} /> Username:</label>
                        <RHFTextField type="text" 
                            className="input-wrap" 
                            id="username" 
                            name="username" 
                            placeholder="Your username (optional)"
                            inputProps={{ maxLength: 16 }}
                            />
                        {!!errors.username?.message && <span className="msg">{errors.username?.message}</span>}
                    </div>
                    <div className={!!errors.email?.message ? 'error' : ''}>
                        <label htmlFor="email"><FontAwesomeIcon icon={faEnvelope} /> Email:</label>
                        <RHFTextField type="email" className="input-wrap" id="email" name="email" placeholder="example@redhotpie.com"  />
                        {!!errors.email?.message && <span className="msg">{errors.email?.message}</span>}
                    </div>
                    <div className={!!errors.category?.message ? 'error' : ''}>
                        <a href="/" id="category" onClick={(onCategoryClicked)}  title="Select category">
                            <FontAwesomeIcon icon={faTag} />
                            <span className="label">Select category</span> 
                            <span className="selection"><span className="value">{category && categories.find(c=>c.ItemID === category) ? categories.find(c=>c.ItemID === category).ItemName : 'Please select' }</span> 
                            <FontAwesomeIcon icon={faChevronRight} /></span>
                        </a>
                        {!!errors.category?.message && <span className="msg">{errors.category?.message}</span>}
                        <CategoryDialog  open={showCategory} close={()=>setShowCategory(false)} val={category} onChange={onCategoryChanged} items={categories} />
                        {/* <Category  close={this.handleCategClose} open={this.state.categoryPopOpen} items={categories} /> */}
                    </div>
                    <div className={!!errors.subject?.message ? 'error' : ''}>
                        <label htmlFor="subject">Subject:</label>
                        <RHFTextField id="subject" className="input-wrap" name="subject" placeholder="Type in your subject" />
                        {!!errors.subject?.message && <span className="msg">{errors.subject?.message}</span>}
                    </div>
                    <div className={!!errors.detail?.message ? 'error' : ''}>
                        <RHFTextAreaAutosize name="detail" className="input-wrap"
                                placeholder="How can we help?" 
                                minRows={5}
                                style={{ width: "100%" }}
                                />
                    </div>
                </fieldset>
                <fieldset className="submit">
                    <p>
                        <label htmlFor="uploader"><FontAwesomeIcon icon={faPaperclip} /> Attach Screenshot</label> 
                        <input id={uploaderElmID} type="file" name="img" onChange={onImageDrop}  />
                    </p>
                    <p>
                        <button className="green btn" type="submit">Send</button>
                    </p>
                    
                </fieldset>
                {
                        attachments.length > 0 
                            &&
                            <ul className="att-list">
                                {
                                    attachments.map((att, i)=>
                                        <li  key={att.FileID} style={{cursor:"pointer"}}>
                                            <span onClick={()=>{onAttachmentClicked(att)}}>Attached {i + 1}</span> 
                                            <span onClick={()=>{ removeAttachment(att)}}>✕</span>
                                        </li>
                                )}
                            </ul>
                            
                            
                    }
                
                {
                    showAttachment && attachment
                    && 
                    <PopupDialog
                    className="att-pop" 
                    classes={{paper: "dialog-paper"}} 
                    open={showAttachment}
                    close={()=>setShowAttachment(false)} 
                    content={<p><img src={attachment.AttachmentUrl} alt="Attachment" /></p>} />
                }
               </FormProvider>
        </div>
        
        </>
    )
}

export default ContactForm;