import StringUtil from "utils/stringUtil";
import { ImageIcons } from "constants/imageIcons";
import { ImagePlaceholder } from "constants/imagePlaceholders";
import ReactLinkify from "react-linkify";

//-----------------------------------------------------------------------------------

const ConvoItem = (props) => {
    const { avatar, name, comment, date, isAdmin, isDesign, attachments, handleAttPopOpen } = props;

    //console.log("is Design - avatar", isDesign + " " + avatar)
    //const imgSrc = isAdmin === true ? ImageIcons[avatar] : isDesign ? ImagePlaceholder[avatar] : avatar
    //const imgSrc = isAdmin === true ? ImageIcons[avatar] : isDesign ? ImagePlaceholder[avatar] : ImagePlaceholder[avatar]
    const imgSrc =  ImageIcons[avatar];
    return (
        <>
            <p className="avator"><a href="/" onClick={e => e.preventDefault()}><img src={imgSrc} alt={``} /></a></p>
            <div className="details">
                <div className="bubble">
                    <p className="name">{name}</p>
                    <p className="date">{date}</p>
                    <p className="comment">
                        <ReactLinkify>
                            {StringUtil.addLineBreaks(comment)}
                        </ReactLinkify>
                        
                    </p>
                    {
                        attachments && attachments.length > 0
                            ? (<ul className="att-list">{attachments.map((url, i) => {
                                return (
                                    <li key={i}><span className="attachment" onClick={() => { handleAttPopOpen(url) }}>Attachment {i + 1}</span></li>
                                );
                            })}</ul>)

                            : null
                    }
                </div>
            </div>
        </>
    )
}

export default ConvoItem;