
//utils
import slug from "slug";

//mui
import { Dialog, 
  //    Slide  
  } from "@mui/material";

//styles
import '../stylesheets/category.scss'

//-------------------------------------------------------------------

const CategoryDialog = ({open, close, val, onChange, items})=>{
    
    
    return (
        <Dialog className="category"
            open={open}
            //TransitionComponent={<Slide direction="up"  />}
            keepMounted
            onClose={close}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            classes={ { paper: "dialog-body"}}
            //disableBackdropClick = {false}
          >
          <div className="body">
            <ul className="ops">
                {
                  items.map(item =>
                      <li key={item.ItemID}>
                        <input type="radio" value={item.ItemID} id={slug(item.ItemName)} name="category" checked={val===item.ItemID} onChange={onChange} />
                        <label htmlFor={slug(item.ItemName)}>{item.ItemName}</label>
                      </li>
                      
                    )
                }
            </ul>
            {/* <p className="link cancel"><a  onClick={this.props.close} className="white btn" title="Click to close">Done</a></p> */}
          </div>

        </Dialog>
    )
}

export default CategoryDialog;