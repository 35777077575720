const handleFetchErrorResponse = (error) => {
    let errorResponse;
    // console.log("error", error);
    // console.log("error.response", error.response);
    // console.log("error.response.data", error.response.data);
    // console.log("(error.request", error.request);
    if(error.ResponseStatus){
        errorResponse = error.ResponseStatus.Message;
    }
    else if(error.response && error.response.data) {
      const {ResponseStatus} = error.response.data;
      if(ResponseStatus && ResponseStatus.Message){
        errorResponse = ResponseStatus.Message;
      }
      else{
        // I expect the API to handle error responses in valid format
        errorResponse = error.response.data;
        // JSON stringify if you need the json and use it later
      }
      
    } else if(error.request) {
      // TO Handle the default error response for Network failure or 404 etc.,
      errorResponse = error.request.message || error.request.statusText;
    } else {
      errorResponse = error.message;
    }

    // console.log("errorResponse", errorResponse)
    return errorResponse;
  }

  export default handleFetchErrorResponse;