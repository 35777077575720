import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrownOpen } from '@fortawesome/free-regular-svg-icons';

const ticketRequireLogin = () => {
    return (
        <>
            <div className="content-404">
                <p className="ico"><FontAwesomeIcon icon={faFaceFrownOpen} /></p>
                <p><strong>Error!</strong></p>
                <p>To view this ticket please <a href="https://redhotpie.com.au/rjsApp" title="Click to go to the login page">login</a>.</p>
            </div>
        </>
    );
}
               
export default ticketRequireLogin;