import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

//constant
import {TICKETVIEW_TYPE} from "../constants/ticketViewType";

//layouts
import MainLayout from "layouts/main";

//hooks
import useTicketView from "../hooks/useTicketView";

//components
import TicketView from "components/ticketView";

import TicketNotFound from "components/ticketNotFound";
import TicketRequireLogin from "components/ticketRequireLogin";

//loading
import Loading from "../components/loading";

//-----------------------------------------------------------------------------

const Ticket = ()=>{
    const navigate = useNavigate();
    const {ticketRef} = useParams();
    const {ticket, isLoaded, viewType} = useTicketView(ticketRef);
    
    useEffect(()=>{
       if(!ticketRef) navigate("/404");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketRef]);

    
    return (
        <MainLayout>
            <div className="page-content">
            
            {
                isLoaded 
                ?   <>
                    { viewType === TICKETVIEW_TYPE.DISPLAY && <><h1>Ticket</h1><TicketView details={ticket} /></> }
                    { 
                        viewType === TICKETVIEW_TYPE.NOTFOUND && 
                        <TicketNotFound />
                    }
                    { 
                        viewType === TICKETVIEW_TYPE.REQUIREDLOGIN && 
                        <TicketRequireLogin />
                    }
                    </>
                : <Loading />
            }
            </div>
        </MainLayout>
    )

}

export default Ticket;