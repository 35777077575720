import { useEffect, useState } from "react";
//hooks
import useSupportService from "../hooks/useSupportService";
//constants
import {TICKETVIEW_TYPE} from "../constants/ticketViewType";

//-----------------------------------------------------------------------------

const useTicketView = (ticketRef)=>{
    const {getTicket} = useSupportService();
    const [ticketState, setTicketState] = useState({isFetching: false, isLoaded: false, ticket: null, viewType:TICKETVIEW_TYPE.LOADING});
    const {ticket, isLoaded, isFetching, viewType} = ticketState;

    const fetchTicket = async()=>{
        if(isLoaded) return Promise.resolve();
        if(isFetching) return Promise.reject("still fetching");
        setTicketState(prev=>({
            ...prev,
            isFetching: true,
            viewType:TICKETVIEW_TYPE.LOADING
        }));
        getTicket(ticketRef)
        .then(
            ({data})=>{
                setTicketState(prev=>({
                    ...prev,
                    ticket: data,
                    viewType:TICKETVIEW_TYPE.DISPLAY
                }))              
            },
            (err) => {               
                setTicketState(prev=>({
                    ...prev,
                    viewType: err?.ResponseStatus?.ErrorCode === "18002" 
                        ? TICKETVIEW_TYPE.REQUIREDLOGIN
                        : TICKETVIEW_TYPE.NOTFOUND
                }))  
                
            }
        )
        .finally(()=>{
            setTicketState(prev=>({
                ...prev,
                isLoaded: true,
                isFetching: false
            })) 
        })

    }

    useEffect(()=>{
        if(!ticketRef) return;
        if(isLoaded) return;
        if(isFetching) return;
        if(ticketRef) fetchTicket();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketRef, isLoaded, isFetching]);

    return {
        ticket, isLoaded, isFetching, viewType
    }
}

export default useTicketView;