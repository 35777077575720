import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//utils
import moment from "moment";
import DateUtil from "utils/dateUtil";

//constants
//import { ImagePlaceholder } from "constants/imagePlaceholders";

//components
import ConvoItem from "./convoItem";
import PopupDialog from './popupDialog';

//styles
import "../stylesheets/detail.scss";

//------------------------------------------------------------------------------------------
const TicketView = (props) => {
    const navigate = useNavigate();
    const { details } = props;
    const { TicketStarter, Responses, Attachments } = details;
    const {TicketRef} = TicketStarter;
    const [convos, setConvos] = useState([]);
    const [showAttachment, setShowAttachment] = useState(false);
    const [attachment, setAttachment] = useState(null);

    const onAttachmentClicked = (att)=>{
        setAttachment(att);
        setShowAttachment(true);
    }

    const onSendClicked = ()=>{
        navigate(`/${TicketRef}/reply`);
    }

    useEffect(() => {
        const messages = Responses.map(response => {
            const dateDisplay = DateUtil.generateMessageTime(response.DateCreated)
            const dateTicks = parseInt(moment(response.DateCreated).format('x'), 10)
            return {
                id: dateTicks,
                //avatar: response.AdminID > 0 ? 'QuestionMarkCircularGrey' : ImagePlaceholder['Placeholder150x150'],
                avatar: response.AdminID > 0 ? 'RHPIco' : 'UserIco',
                name: response.ResponseBy,
                date: dateDisplay,
                comment: response.Message,
                isAdmin: response.AdminID > 0,
                isDesign: false,
                attachments: response.Attachments
            }
        });
        messages.push({
            id: parseInt(moment(TicketStarter.Date).format('x'), 10),
            //avatar: ImagePlaceholder['Placeholder150x150'],
            avatar: 'UserIco',
            name: "",
            date: DateUtil.generateMessageTime(TicketStarter.Date),
            comment: TicketStarter.Message,
            isAdmin: false,
            attachments: Attachments
        });
        setConvos(messages.filter(c=>c.comment).sort((a, b) => { return b.id - a.id }));
        
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {
                convos.length > 0
                && 
                <div className="comments">
                    <div className="list-container">
                        <ul>
                            {
                                convos.map((c, idx) =>
                                    <li key={idx} className={!c.isAdmin && "user"}>
                                        <ConvoItem
                                            avatar={c.avatar}
                                            name={c.name}
                                            comment={c.comment}
                                            date={c.date}
                                            isAdmin={c.isAdmin}
                                            isDesign={c.isDesign}
                                            attachments={c.attachments}
                                            handleAttPopOpen={onAttachmentClicked}
                                        />
                                    </li>
                                    

                                )
                            }
                        </ul>

                        <div className="text-input">
                            <p className="field"><textarea className="dontputmeinabox" onClick={onSendClicked} onFocus={onSendClicked} placeholder="Enter your reply..." row="1" id="taCommunityPostComment"></textarea></p>
                            <p className="send"><button className="green btn" onClick={onSendClicked}>Send</button></p>
                        </div>
                    </div>
                    {
                        showAttachment && attachment
                        && 
                        <PopupDialog
                        className="att-pop" 
                        classes={{paper: "dialog-paper"}} 
                        open={showAttachment}
                        close={()=>setShowAttachment(false)} 
                        content={<p><img src={attachment} alt="Attachment" /></p>} />
                    }
                </div>
            }
        </>
    )
}

export default TicketView;