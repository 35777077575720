import PropTypes from 'prop-types';
// form
import { 
   useFormContext, 
  Controller } from 'react-hook-form';
// @mui
import { TextareaAutosize, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextAreaAutosize.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextAreaAutosize({ name, ...other }) {
   const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
         <TextareaAutosize {...field} 
            //todo
            // error={!!error} 
            // helperText={error?.message} 
            {...other} />
         {error && <FormHelperText className='msg'>{error?.message}</FormHelperText>}
        </>
       
      )}
    />
  );
}
