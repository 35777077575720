
//constants
import { ImageIcons } from '../constants/imageIcons';
//mui
import { Dialog, 
    //    Slide  
    } from "@mui/material";
//styles
import "../stylesheets/popup.scss";

//-------------------------------------
const PopupDialog = (props)=>{
    const {className, classes, open, close, content} = props;
      return(
          <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description" 
          className={className}
          classes={classes}
          >
              <div className="content">
                  <header><p className="close-btn"><a href="/" onClick={(e)=>{e.preventDefault(); close();}}  title="Click to close"><img src={ImageIcons['CrossThinWhite']} alt="X" /></a></p></header>
                  <div className="body">{content}</div>
              </div>
          </Dialog>
      );
}

export default PopupDialog;