export const HOST_API = (process.env.NODE_ENV === 'development')
                ? //'http://localhost:57310'
                  'https://tstsrvs.redhotpie.com.au'
                  //  'https://tstsrvs.redhotpie.com.au'
                : //'https://tstsrvs.redhotpie.com.au'; 
                'https://srvsr.redhotpie.com.au';

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};