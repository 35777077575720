//layouts
import MainLayout from "layouts/main";
//components
import ContactForm from "../components/contactForm";

import "../stylesheets/contact.scss";

//--------------------------------------------------------------------

const Contact = ()=>{
    return (
        <MainLayout>
             <div className="support">
                <ContactForm />
             </div>
        </MainLayout>
    )

}

export default Contact;
