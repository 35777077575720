
import MainLayout from "layouts/main";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceFrownOpen } from '@fortawesome/free-regular-svg-icons';

const NotFound = ()=>{

    return (
        <MainLayout>
            <div className="page-content">
                <div className="content-404">
                    <p className="ico"><FontAwesomeIcon icon={faFaceFrownOpen} /></p>
                    <p><strong>404</strong></p>
                    <p>Page not found</p>
                </div>
                

            </div>
        </MainLayout>
    )
}

export default NotFound;